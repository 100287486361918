import axios from "axios";

const makeRequest = axios.create({
  // baseURL: "https://api.airtable.com/v0/appHdKA9RYY3fiyw6/",
  baseURL: "https://api.heg.ai/hub/",
  timeout: 10000,
  headers: {
    "Authorization": "Bearer " + (localStorage.getItem('token') ? localStorage.getItem('token') : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9'),
    "Content-Type": "application/json",
  },
});

export default makeRequest;
